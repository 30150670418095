.di-activator {
    background-color: var(--di-activator-bg-color);
}

.btn.default-chat-icon {
    width:70px;
    height:70px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 12px;
    box-shadow: rgba(110, 170, 216, 0.4) 0px 2px 12px;
    background-color: var(--di-activator-btn-bg-color) !important;
    color: var(--di-activator-btn-color) !important;
    border: var(--di-activator-btn-border) !important;
    
    &:focus-visible {
        color: #6EAAD8;
        box-shadow: rgba(110, 170, 216, 0.4) 0px 2px 12px;
        outline: 2px solid red;
    }
    
}