@import "~@chatscope/chat-ui-kit-styles/themes/default/variables";
// Musi być nadpisane oryginalnym prefixem, bo wcześniej bootstrap nadpisuje swoim "bs"
$prefix: "cs";

////////////

// Variables
//
//
// Default font family
//

$default-font-family: var(--cs-default-font-family);

// Colors
//


$color-primary: var(--cs-color-primary);
$color-primary-light: var(--cs-color-primary-light);
$color-primary-dark: var(--cs-color-primary-dark);
$color-primary-dark: #6EAAD8;

$color-secondary: #91ECEC;
$color-secondary-light: #C2F9F9;
$color-secondary-dark: #64D3D3;

// Material text colors
// http://marcodiiga.github.io/rgba-to-rgb-conversion
// Hex values are calculated based on white background 
// If you change background color rgb color will change, that is why its better to use rgba with opacity!
$color-text: rgba(0, 0, 0, .87); // #212121
$color-text-medium: rgba(0, 0, 0, .60); // #666666
$color-text-disabled: rgba(0, 0, 0, .38); // #9e9e9e
$color-text-inverted: invert($color-text);

// Base colors
//
// It's not recommended to change this colors because white is white and black is... black :)
$color-white: #fff;
$color-black: #000;

$default-bg-color: $color-white;

// Element colors
//

$default-border-color: var(--cs-default-border-color);

// State colors
//

$color-hover: $color-text;
$color-bg-hover: var(--cs-color-bg-hover);
$color-focus: $color-text;
$color-bg-focus: var(--cs-color-bg-focus);
$color-selected: darken($color-primary-dark, 25%);
$color-bg-selected: $color-bg-focus;
$color-activated: darken($color-primary-dark, 25%) ;
$color-bg-activated: $color-bg-focus;
$color-active: $color-primary-dark;
$color-bg-active: $color-bg-activated;
$color-disabled: $color-text-disabled;
$color-bg-disabled: rgba(0, 0, 0, .38);

// Functional colors
//

$color-danger: #ec1212;
$color-error: $color-danger;

// Border radius
//
// Default border radius for elements

$default-border-radius: 0.7em;

// Opacity
//

$disabled-opacity: 0.38;

///////////////////////////////////////// Components /////////////////////////////////////////

// Avatar
// 

// Sizes
$avatar-xs-width: 16px;
$avatar-sm-width: 26px;
$avatar-md-width: 42px;
$avatar-lg-width: 68px;
$avatar-fluid-width: 100%;

// Avatar group
// 

// Sizes
$avatar-group-xs-width: 16px;
$avatar-group-sm-width: 32px;
$avatar-group-md-width: 100%;
$avatar-group-lg-width: 80px;
$avatar-group-fluid-width: 100%;

$avatar-group-md-min-width: 40px;

// Buttons
// 
// General button element and dedicated (function specific) buttons

// Borders
$button-border: solid 1px $default-border-color;
$button-border-radius: $default-border-radius;

// Colors
$button-color: $color-primary-dark;
$button-bg-color: transparent;

// Fonts
$button-font-size: 1em;
$button-line-height: 1.5;

// Opacity
$button-hover-opacity: 0.6;
$button-disabled-opacity: $disabled-opacity;

// Spacing
$button-margin: 0.1em 0.2em;
$button-padding: 0.2em 0.7em;

// Add user button 

$button-adduser-color: $button-color;
$button-adduser-bg-color: $button-bg-color;

// Arrow button

$button-arrow-color: var(--cs-button-arrow-color);
$button-arrow-bg-color: $button-bg-color;

// Ellipsis button

$button-ellipis-color: $button-color;
$button-ellipis-bg-color: $button-bg-color;

// Info button

$button-info-color: $button-color;
$button-info-bg-color: $button-bg-color;

// Star button

$button-star-color: $button-color;
$button-star-bg-color: $button-bg-color;

// Video call button

$button-videocall-color: $button-color;
$button-videocall-bg-color: $button-bg-color;

// Voice call button

$button-voicecall-color: $button-color;
$button-voicecall-bg-color: $button-bg-color;

// Send button

$button-send-color: var(--cs-button-send-color);
$button-send-bg-color: $button-bg-color;

// Attachment button

$button-attachment-color: $button-color;
$button-attachment-bg-color: $button-bg-color;

// Chat container
//

// Borders
$chat-container-message-input-border-top: 0;
$chat-container-message-input-border-right: solid 0px $default-border-color;
$chat-container-message-input-border-bottom: solid 0px $default-border-color;
$chat-container-message-input-border-left: solid 0px $default-border-color;

// Colors 
$chat-container-color: $color-text;
$chat-container-bg-color: $default-bg-color;
$chat-container-message-input-color: $color-text;
$chat-container-message-input-bg-color: var(--cs-chat-container-message-input-bg-color);
$chat-container-input-toolbox-color: $color-text;
$chat-container-input-toolbox-bg-color: $default-bg-color;

// Sizes
$chat-container-min-width: 180px;

// Conversation
//

// Borders
$conversation-border-top: var(--cs-conversation-border-top);
$conversation-border-right: var(--cs-conversation-border-right);
$conversation-border-bottom: var(--cs-conversation-border-bottom);
$conversation-border-left: var(--conversation-border-left);
$conversation-active-border-top: 0 !default;
$conversation-active-border-right: 0 !default;
$conversation-active-border-bottom: var(--cs-conversation-active-border-bottom);
$conversation-active-border-left: 0 !default;
$conversation-activated-border-top: 0 !default;
$conversation-activated-border-right: 0 !default;
$conversation-activated-border-bottom: var(--cs-conversation-activated-border-bottom);
$conversation-activated-border-left: 0 !default;

// Colors
$conversation-color: $color-text;
$conversation-bg-color: var(--cs-conversation-bg-color);
$conversation-unread-color: $color-white;
$conversation-unread-bg-color: $color-danger;
$conversation-hover-bg-color: var(--cs-conversation-hover-bg-color);
$conversation-unread-dot-color: #2e63e8;
$conversation-name-color: var(--cs-conversation-name-color);
$conversation-name-activated-color: var(--cs-conversation-name-activated-color);
$conversation-info-color: var(--cs-conversation-info-color);
$conversation-info-activated-color: var(--cs-conversation-info-activated-color);
$conversation-active-color: $conversation-color;
$conversation-active-bg-color: var(--cs-conversation-active-bg-color);
$conversation-activated-color: var(--cs-conversation-activated-color);
$conversation-activated-bg-color: var(--cs-conversation-activated-bg-color);

// Fonts
$conversation-font-family: $default-font-family;
$conversation-unread-font-size: 0.75em;
$conversation-last-sender-name-font-weight: normal;
$conversation-info-content-font-weight: normal;
$conversation-info-activated-font-weight: var(--cs-conversation-info-activated-font-weight);
$conversation-activated-font-weight: var(--cs-conversation-activated-font-weight);
$conversation-name-activated-font-weight: var(--cs-conversation-name-activated-font-weight);

// Sizes
$conversation-unread-dot-width: 0.7em;

// Spacing
$conversation-operations-margin: 0 0 0 auto;
$conversation-last-activity-time-margin: 0 0 0 auto;
$chat-container-input-toolbox-margin: 0;
$chat-container-input-toolbox-padding: 0 0.8em 0.17em 0;

// Conversation header
//

// Borders
$conversation-header-border-top: solid 0px $default-border-color;
$conversation-header-border-right: solid 0px $default-border-color;
$conversation-header-border-bottom: var(--cs-conversation-header-border-bottom);
$conversation-header-border-left: solid 0px $default-border-color;

// Colors
$conversation-header-bg-color: var(--cs-conversation-header-bg-color);
$conversation-header-color: var(--cs-conversation-header-color);
$conversation-header-user-name-color: $conversation-header-color;
$conversation-header-user-name-bg-color: $conversation-header-bg-color;
$conversation-header-info-color: var(--cs-conversation-header-info-color);
$conversation-header-info-bg-color: $conversation-header-bg-color;
$conversation-header-action-button-color: normal;
$conversation-header-action-button-bg-color: transparent;
$conversation-header-actions-color: $color-text;
$conversation-header-actions-bg-color: transparent;

// Fonts
$conversation-header-font-family: $default-font-family;
$conversation-header-font-size: 1em;

// Sizes
$conversation-header-content-min-width: 0;

// Spacing
$conversation-header-actions-margin: 0 0 0 0.5em;

// Conversation list
//

// Colors
$conversation-list-color: $color-text;
$conversation-list-bg-color: transparent;
$conversation-list-loading-more-bg-color: transparent;

// Sizes
$conversation-list-loading-more-height: 1.2em;

// Spacing
$conversation-list-loading-more-padding: 2px 0;

// Expansion panel
//

// Borders
$expansion-panel-border: solid 1px $default-border-color;

// Colors
$expansion-panel-color: $color-text;
$expansion-panel-bg-color: $default-bg-color;
$expansion-panel-header-color: $color-text-medium;
$expansion-panel-header-bg-color: $color-primary-light;
$expansion-panel-header-hover-color: $expansion-panel-header-color;
$expansion-panel-header-hover-bg-color: $color-bg-hover;
$expansion-panel-header-open-color: $expansion-panel-header-color;
$expansion-panel-header-open-bg-color: $expansion-panel-header-bg-color;
$expansion-panel-content-color: $color-text;
$expansion-panel-content-bg-color: $default-bg-color;
$expansion-panel-content-open-color: $expansion-panel-content-color;
$expansion-panel-content-open-bg-color: $expansion-panel-content-bg-color;

// Fonts 
$expansion-panel-font-family: $default-font-family;
$expansion-panel-font-weight: normal;
$expansion-panel-font-size: inherit;
$expansion-panel-font-variant: normal;

$expansion-panel-header-font-family: $default-font-family;
$expansion-panel-header-font-weight: bold;
$expansion-panel-header-font-size: 0.75em;
$expansion-panel-header-font-variant: normal;

$expansion-panel-content-font-family: $default-font-family;
$expansion-panel-content-font-weight: normal;
$expansion-panel-content-font-size: 0.8em;
$expansion-panel-content-font-variant: normal;


// Input toolbox
//

// Colors
$input-toolbox-send-btn-color: $color-secondary;
$input-toolbox-upload-btn-color: $color-secondary;

// Fonts
$input-toolbox-btn-font-size: 1.2em;

// Spacing
$input-toolbox-btn-margin: 0 0.5em 0 0;
$input-toolbox-margin: 0;
$input-toolbox-padding: 0 0 0.4em 0;

// Loader
//

// Borders
$loader-border-width: 0.2em;

// Colors
$loader-color: $color-text;
$loader-bg-color: transparent;
$loader-spinner-color: $color-primary-dark;
$loader-spinner-bg-color: $color-primary;

// Sizes
$loader-width: 1.8em;
$loader-height: 1.8em;

// Main container
//

// Borders
$main-container-border: var(--cs-main-container-border);
$main-container-conversation-list-border-top: solid 0px $default-border-color;
$main-container-conversation-list-border-right: var(--cs-main-container-conversation-list-border-right);
$main-container-conversation-list-border-bottom: solid 0px $default-border-color;
$main-container-conversation-list-border-left: solid 0px $default-border-color;
$main-container-chat-container-border-right: solid 0px $default-border-color;
$main-container-sidebar-right-border-top: solid 0px $default-border-color;
$main-container-sidebar-right-border-right: solid 0px $default-border-color;
$main-container-sidebar-right-border-bottom: solid 0px $default-border-color;
$main-container-sidebar-right-border-left: solid 1px $default-border-color;

// Colors
$main-container-color: $color-text;
$main-container-bg-color: var(--cs-main-container-bg-color);

// Fonts
$main-container-font-size: var(--cs-main-container-font-size); // Font size for child elements are relative to this size

// Sizes
$main-container-conversation-list-flex-basis: 300px;
$main-container-sidebar-width: 300px;
$main-container-sidebar-min-width: $main-container-sidebar-width;

$main-container-sidebar-left-max-width: 320px;
$main-container-sidebar-right-min-width: 150px;
$main-container-sidebar-right-max-width: 320px;

// Message
// 

// Boolean flags
$show-message-incoming-sender-name: false;
$show-message-incoming-single-sender-name: true;
$show-message-incoming-first-sender-name: true;
$show-message-incoming-last-sender-name: false;

$show-message-outgoing-sender-name: false;
$show-message-outgoing-single-sender-name: false;
$show-message-outgoing-first-sender-name: false;
$show-message-outgoing-last-sender-name: false;

$show-message-incoming-sent-time: false;
$show-message-incoming-single-sent-time: true;
$show-message-incoming-first-sent-time: true;
$show-message-incoming-last-sent-time: false;

$show-message-outgoing-sent-time: false;
$show-message-outgoing-single-sent-time: true;
$show-message-outgoing-first-sent-time: true;
$show-message-outgoing-last-sent-time: false;

// Avatar flags
$show-message-incoming-avatar: false;
$show-message-incoming-single-avatar: true;
$show-message-incoming-first-avatar: false;
$show-message-incoming-last-avatar: true;

$show-message-outgoing-avatar: false;
$show-message-outgoing-single-avatar: true;
$show-message-outgoing-first-avatar: false;
$show-message-outgoing-last-avatar: true;

// Borders
$message-base-radius: $default-border-radius;

$message-border-radius: 0;

// Border radius for single incoming message
$message-incoming-single-border-radius: $message-border-radius;

// Border radius for first incoming message
$message-incoming-first-border-radius: $message-border-radius $message-border-radius $message-border-radius 0;

// Border radius for last incoming message
$message-incoming-last-border-radius: 0 $message-border-radius $message-border-radius $message-border-radius;

// Border radius for single outgoing message
$message-outgoing-single-border-radius: $message-border-radius;

// Border radius for first outgoing message
$message-outgoing-first-border-radius: $message-border-radius $message-border-radius 0 $message-border-radius;

// Border radius for last outgoing message
$message-outgoing-last-border-radius: $message-border-radius $message-border-radius $message-border-radius $message-border-radius;

// Border radius for content
$message-content-border-radius: $message-base-radius $message-base-radius $message-base-radius $message-base-radius;

// Border radius for normal incoming message content
$message-incoming-content-border-radius: 0 $message-base-radius $message-base-radius 0;

// Border radius for single incoming message content
$message-incoming-content-single-border-radius: 0 $message-base-radius $message-base-radius $message-base-radius;

// Border radius for first incoming message content
$message-incoming-content-first-border-radius: 0 $message-base-radius $message-base-radius 0;

// Border radius for last incoming message content
$message-incoming-content-last-border-radius: 0 $message-base-radius 0 $message-base-radius;

// Border radius for normal outgoing message content
$message-outgoing-content-border-radius: $message-base-radius 0 0 $message-base-radius;

// Border radius for single outgoing message content
$message-outgoing-content-single-border-radius: $message-base-radius $message-base-radius 0 $message-base-radius;

// Border radius for first outgoing message content
$message-outgoing-content-first-border-radius: $message-base-radius 0 0 $message-base-radius;

// Border radius for last outgoing message content
$message-outgoing-content-last-border-radius: $message-base-radius 0 $message-base-radius $message-base-radius;

// Colors
$message-color: $color-text;
$message-bg-color: transparent;

$message-header-color: lighten($color-text, 30);
$message-header-bg-color: transparent;

$message-footer-color: lighten($color-text, 30);
$message-footer-bg-color: transparent;

$message-incoming-color: $message-color;
$message-incoming-bg-color: $message-bg-color;

$message-outgoing-color: $message-color;
$message-outgoing-bg-color: transparent;

$message-sender-name-color: $message-header-color;
$message-sender-name-bg-color: transparent;

$message-sent-time-color: $message-header-color;
$message-sent-time-bg-color: transparent;

$message-content-color: $color-text;
$message-content-bg-color: $color-primary;

$message-content-incoming-color: $color-text;
$message-content-incoming-bg-color: $message-content-bg-color;

$message-content-outgoing-color: $color-text;
$message-content-outgoing-bg-color: $color-primary-dark;

// Fonts
$message-font-size: 1em;
$message-font-family: $default-font-family;

$message-content-font-family: $default-font-family;
$message-content-font-weight: normal;
$message-content-font-size: 0.91em;
$message-content-font-variant: normal;

// Sizes
$message-avatar-width: 42px;

// Spacing
$message-margin:0; // Default margin for message
$message-only-child-margin: 0.2em 0 0 0;
$message-not-only-child-margin: 0.2em 0 0 0;

$message-header-margin: 0 0.2em 0.1em 0.2em;
$message-footer-margin: 0.1em 0.2em 0 0.2em;

$message-content-margin-top: 0;
$message-monologue-margin-top: 0.4em;

$message-avatar-horizontal-margin: 8px; // Pixels because need to calculate with avatar width
$message-avatar-margin: 0 $message-avatar-horizontal-margin 0 0;
$message-incoming-avatar-margin: 0 $message-avatar-horizontal-margin 0 0;
$message-outgoing-avatar-margin: 0 0 0 $message-avatar-horizontal-margin;

// Message group
//

// Borders
$message-group-message-base-radius: $default-border-radius;
// Border radius for normal incoming message content
$message-group-incoming-message-content-border-radius: 0 $message-group-message-base-radius $message-group-message-base-radius 0;
// Border radius for single incoming message content
$message-group-incoming-message-content-single-border-radius: 0 $message-group-message-base-radius $message-group-message-base-radius $message-group-message-base-radius;
// Border radius for first message content in incoming group
$message-group-incoming-message-content-first-border-radius: 0 $message-group-message-base-radius $message-group-message-base-radius 0;
// Border radius for last message content in incoming group
$message-group-incoming-message-content-last-border-radius: 0 $message-group-message-base-radius 0 $message-group-message-base-radius;
// Border radius for normal outgoing message content
$message-group-outgoing-message-content-border-radius: $message-group-message-base-radius 0 0 $message-group-message-base-radius;
// Border radius for single outgoing message content
$message-group-outgoing-message-content-single-border-radius: $message-group-message-base-radius $message-group-message-base-radius 0 $message-group-message-base-radius;
// Border radius for first message content in outgoing group
$message-group-outgoing-message-content-first-border-radius: $message-group-message-base-radius 0 0 $message-group-message-base-radius;
// Border radius for last message content in outgoing group
$message-group-outgoing-message-content-last-border-radius: $message-group-message-base-radius 0 $message-group-message-base-radius $message-group-message-base-radius;

//  Colors
$message-group-header-color: $color-text-medium;
$message-group-header-bg-color: transparent;
$message-group-footer-color: $color-text-medium;
$message-group-footer-bg-color: transparent;
$message-group-message-content-bg-color: $color-primary;

$message-group-message-content-incoming-color: var(--cs-message-group-message-content-incoming-color);
$message-group-message-content-incoming-bg-color: var(--cs-message-group-message-content-incoming-bg-color);

$message-group-message-content-outgoing-color: var(--cs-message-group-message-content-outgoing-color);
$message-group-message-content-outgoing-bg-color: var(--cs-message-group-message-content-outgoing-bg-color);

// Fonts
$message-group-header-font-size: 0.8em;
$message-group-header-font-family: $default-font-family;

$message-group-footer-font-size: 0.8em;
$message-group-footer-font-family: $default-font-family;

// Spacing
$message-group-margin: 0.4em 0 0 0;
$message-group-header-margin: 0 0 0.2em 0;
$message-group-footer-margin: 0.2em 0 0 0;

$message-group-avatar-horizontal-margin: 8px;

$message-group-header-padding: 0;
$message-group-footer-padding: 0;

// Message input
//

// Borders
$message-input-border-top: none;
$message-input-border-right: none;
$message-input-border-bottom: none;
$message-input-border-left: none;

$message-input-content-editor-wrapper-border-radius: $default-border-radius;

$message-input-content-editor-border-top-width: 0;    // These variables
$message-input-content-editor-border-right-width: 0;  // are used
$message-input-content-editor-border-bottom-width: 0; // for some calculations
$message-input-content-editor-border-left-width: 0;   // by _message-input.scss

$message-input-content-editor-border-top: $message-input-content-editor-border-top-width none;
$message-input-content-editor-border-right: $message-input-content-editor-border-right-width none;
$message-input-content-editor-border-bottom: $message-input-content-editor-border-bottom-width none;
$message-input-content-editor-border-left: $message-input-content-editor-border-left-width none;

// Colors
$message-input-bg-color: $default-bg-color;
$message-input-content-editor-color: var(--cs-message-input-content-editor-color);
$message-input-content-editor-wrapper-bg-color: var(--cs-message-input-content-editor-wrapper-bg-color);
$message-input-content-editor-container-bg-color: $message-input-content-editor-wrapper-bg-color;
$message-input-content-editor-bg-color: $message-input-content-editor-wrapper-bg-color;
$message-input-content-editor-disabled-color: $color-text-medium;
$message-input-content-editor-disabled-bg-color: rgba($message-input-content-editor-bg-color, 0.38);

// Fonts
$message-input-content-editor-font-family: $default-font-family;
$message-input-content-editor-container-font-size: 0.94em;

// Sizes
$message-input-max-lines: 4;

// Spacing
$message-input-placeholder-color: var(--cs-message-input-placeholder-color);
$message-input-padding: 0;

$message-input-content-editor-wrapper-margin: 0;
$message-input-content-editor-wrapper-padding: 0.6em 0.9em;
$message-input-content-editor-container-padding-top: 0em;     // These variables
$message-input-content-editor-container-padding-right: 0em;   // are used 
$message-input-content-editor-container-padding-bottom: 0em;  // for some calculations
$message-input-content-editor-container-padding-left: 0em;    // by _message-input.scss
$message-input-content-editor-container-padding: $message-input-content-editor-container-padding-top $message-input-content-editor-container-padding-right $message-input-content-editor-container-padding-bottom $message-input-content-editor-container-padding-left;

// !!! Be careful, when set this to unitless value - calculations below will not work properly
// Also check if scroll is working properly after changing this value
$message-input-content-editor-container-line-height: 1.35em;

// Message list
//

// Colors
$message-list-color: $color-text;
$message-list-bg-color: var(--cs-message-list-bg-color);
$message-list-typing-indicator-bg-color: $color-white;
$message-list-loading-more-bg-color: $color-white;

// Sizes
$message-list-loading-more-height: 1.2em;

// Spacing
$message-list-scroll-wrapper-padding: 0 1.2em 0 0.8em;
$message-list-loading-more-padding: 2px 0;
$message-list-message-separator-margin-top: 1.2em;

// Message separator
//

// Colors
$message-separator-color: var(--cs-message-separator-color);
$message-separator-bg-color: var(--cs-message-separator-bg-color);
$message-separator-font-size: 0.8em;
$message-separator-font-family: $default-font-family;

$message-separator-content-color: $message-separator-color;
$message-separator-content-bg-color: $message-separator-bg-color;

$message-separator-line-color: $message-separator-color;

// Spacing
$message-separator-left-line-margin: 0 1em 0 0;
$message-separator-right-line-margin: 0 0 0 1em;

// Overlay
//

// Colors
$overlay-bg-color: rgba(198,227,250,0.38);

// Search
//

// Borders
$search-border-radius: $default-border-radius;

//  Colors
$search-bg-color: $color-primary;
$search-color: $color-text;
$search-icon-color: $color-primary-dark;
$search-clear-icon-color: $search-icon-color;
$search-clear-icon-color-hover: rgba($color-primary-dark, 0.6);
$search-placeholder-color: $color-text;
$search-input-bg-color: $search-bg-color;
$search-disabled-color: $color-disabled;
$search-disabled-bg-color: $search-bg-color;
$search-input-disabled-bg-color: $search-disabled-bg-color;

// Fonts
$search-font-family: inherit;
$search-font-size: inherit;
$search-input-font-family: inherit;
$search-input-font-size: 0.8em;

// Opacity
$search-disabled-opacity: $disabled-opacity;

// Sidebar
//

// Colors
$sidebar-right-bg-color: $default-bg-color;
$sidebar-left-bg-color: var(--cs-sidebar-left-bg-color);

// Status
// 

// Colors
$status-available-color: #00a27e;
$status-unavailable-color: #a66d00;
$status-away-color: #fc8b00;
$status-dnd-color: $color-danger;
$status-invisible-color: #c2d1d9;
$status-eager-color: #ffee00;

$status-selected-color: $color-selected;
$status-selected-bg-color: $color-bg-selected;

$status-available-name-color: $color-text;
$status-unavailable-name-color: $color-text;
$status-away-name-color: $color-text;
$status-dnd-name-color: $color-text;
$status-invisible-name-color: $color-text;
$status-eager-name-color: $color-text;

// Fonts
$status-xs-fontsize: 1em;
$status-sm-fontsize: 1em;
$status-md-fontsize: 1em;
$status-lg-fontsize: 1em;

// Sizes
$status-xs-width: 4px;
$status-sm-width: 8px;
$status-md-width: 11px;
$status-lg-width: 14px;
$status-fluid-width: 100%;

// Spacing
$status-name-margin: 0 0 0 0.58em;

// Status list
// 

// Colors
$status-list-color: $color-text;
$status-list-bg-color: $default-bg-color;

$status-list-margin: 0;
$status-list-padding: 1em;

$status-list-item-margin: 0;
$status-list-item-padding: 0.4em 0.8em!default;

$status-list-item-hover-color: $color-text-medium;
$status-list-item-hover-bg-color: $color-bg-hover;

$status-list-item-focus-color: $color-focus;
$status-list-item-focus-bg-color: $color-bg-focus;

$status-list-item-selected-color: $color-selected;
$status-list-item-selected-bg-color: $color-bg-selected;

$status-list-item-active-color: $color-text;
$status-list-item-active-bg-color: $color-bg-active;

// Typing indicator
//

// Colors
$typing-indicator-text-color: $color-primary-dark;
$typing-indicator-text-bg-color: transparent;

$typing-indicator-indicator-bg-color: transparent;
$typing-indicator-dot-bg-color: $color-primary-dark;

// Fonts
$typing-indicator-font-family: $default-font-family;
$typing-indicator-font-size: inherit;

// Sizes
$typing-indicator-dot-size: 4px;

// Spacing
$typing-indicator-dot-spacing: 3px;
$typing-indicator-text-margin: 0 0 0 0.5em;

